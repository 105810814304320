import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import Modal from "../modal/citizen-modal"

import "./hero.css"

const HeroCitizen = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(relativePath: { eq: "new-hero-Background.png" }) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1920
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-home"
        // preserveStackingContext
        style={{
          backgroundPosition: "top center",
          height: "600px",
          position: "relative",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="hero-custom-quickbase">
                <h1 className="page-title font-weight-medium mt-0 mb-4">
                  Set Your Citizen Developers on the Path to Success
                </h1>
                <p className="mb-3">
                  We help organizations stand up and govern citizen development
                  programs encouraging safe, scalable innovation. Accomplish
                  more. Risk less.
                </p>
                <Modal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroCitizen
