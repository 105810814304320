import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout-citizen"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

import CustomAccordion from "../components/accordion.js"

import Hero from "../components/hero/hero-citizen-development.js"
// import bethany from "../images/bethany.jpg"
// import david from "../images/david.jpeg"
// import thomas from "../images/thomas.jpeg"

//components
const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))
const Modal = loadable(() =>
  pMinDelay(import("../components/modal/citizen-modal"), 500)
)
const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper-partner.js"), 500)
)

class citizenDevelopmentPage extends React.Component {
  render() {
    const siteTitle = "Citizen Development Implementation Support and Guidance"
    const siteDescription =
      "We help organizations navigate the numerous challenges they face with implementing and managing a citizen development program. Scale faster. Stay compliant."
    const accordionData = [
      {
        title: <>How Does a Citizen Developer Contribute to a Project?</>,
        content: (
          <>
            <div className="py-3">
              <p>
                Citizen developers use their business expertise (daily
                experience with processes and workflows) to create applications
                using low-code or no-code platforms. They are often full-time
                employees who create applications for their departments on the
                side.
              </p>
            </div>
          </>
        ),
      },
      {
        title: <>When Can You Implement a Citizen Development Program?</>,
        content: (
          <>
            <div className="py-3">
              <p>
                If you can identify potential citizen developers in your
                company, you’re ready to implement a citizen developer program.
                However, you’ll need tools, training, and a framework to start
                on the right path.
              </p>
            </div>
          </>
        ),
      },
      {
        title: <>How Do You Implement a Citizen Developer Program?</>,
        content: (
          <>
            <div className="py-3">
              <p>
                You need to have a citizen developer framework in place before
                building out a citizen developer program. This ensures your team
                members have the guidance and guardrails they need to succeed.
                At the same time, it prevents your low-code initiatives from
                turning into another form of shadow IT. With a citizen developer
                framework, your team can explore creative solutions to problems
                without putting your business at risk or increasing strain on
                IT.
              </p>
            </div>
          </>
        ),
      },
      {
        title: (
          <>What Type of Businesses Benefit Most from Citizen Development?</>
        ),
        content: (
          <>
            <div className="py-3">
              <p>
                Citizen development can support various businesses, helping them
                meet their needs. It all depends on the challenge you face.
                Whether you run a small company that’s looking for ways to
                improve workflows or a larger company with an overworked IT
                department, you can implement a Citizen Development program.
              </p>
            </div>
          </>
        ),
      },
      {
        title: <>How Do You Govern Citizen Development?</>,
        content: (
          <>
            <div className="py-3">
              <p>
                Governing your citizen development program requires a top-down
                approach. Once you have a citizen development framework program
                in place, you must create a culture of transparency and support
                for the movement. Additionally, IT professionals must have
                visibility and control over all parts to maintain quality and
                security. Your citizen development framework will ensure your
                team collectively moves toward getting the most out of your
                low-code applications.
              </p>
            </div>
          </>
        ),
      },
      {
        title: <>How Can Companies Leverage Citizen Developers More?</>,
        content: (
          <>
            <div className="py-3">
              <p>
                Choosing the right technology goes a long way to ensuring your
                citizen developers can create the best solutions. Frameworks,
                governance, and a top-down adoption create a strong foundation
                for successful citizen developers' initiatives. However, if you
                want to speed up the process more, consider working with citizen
                development consultants. Consultants will know how to stand up
                these programs faster and provide you with the support,
                guidance, and frameworks you need to succeed.
              </p>
            </div>
          </>
        ),
      },
    ]
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo
          title="Citizen Development Support | Quandary Consulting Group"
          description="We help organizations stand up effective citizen development programs that are compliant, secure, and capable. Scale your IT team today!"
        />
        <div id="main">
          {/* <ExitIntentModal /> */}
          <LazyLoadComponent>
            <section
              id="quandary-help-wrapper"
              className="quandary-help-wrapper mt-5"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h2 className="font-weight-medium">
                      ORGANIZATIONS <span className="text-green">TRUST</span>{" "}
                      QUANDARY CONSULTING GROUP
                    </h2>
                  </div>
                </div>

                <PartnerSwiper />
              </div>
            </section>

            {/* </LazyLoadComponent> */}
            {/* <LazyLoad height={200} once> */}
            <section
              className="quickbase-support"
              style={{ padding: "5rem 0" }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col text-center">
                    <h2 className="my-3 font-weight-medium text-uppercase">
                      GET A CITIZEN DEVELOPMENT
                      <br /> PROGRAM THAT WORKS
                    </h2>
                    <p>
                      Transform low-code application development from chaos into
                      an organized system that helps you
                      <br /> achieve your business goals.
                    </p>
                    <div className="col-lg-5 mx-auto text-center pt-3 ">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="quickbase-integrations">
              <div className="container quickbase-integrations-padding">
                <div className="row">
                  <div className="col-lg-12 text-center pt-5">
                    <h2 className="h3 text-white mt-4">
                      THE BENEFITS OF CITIZEN DEVELOPMENT ARE REAL
                    </h2>
                    <p className="text-white">
                      We help teams empower their citizen developers, so they
                      can build the business their customers dream about.
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/reduce-pressure.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      REDUCE PRESSURE ON IT
                    </p>
                    <p className="pt-2 text-white">
                      Tackle endless IT backlog with citizen developers who can
                      knock out minor builds.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/framework.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      COMBAT SHADOW IT
                    </p>
                    <p className="pt-2 text-white">
                      Govern untamed low-code application development with clear
                      frameworks and guardrails.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/teamwork.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      INSPIRE YOUR TEAM
                    </p>
                    <p className="pt-2 text-white">
                      Learn how to build safe sandbox environments that inspire
                      innovation and creativity.
                    </p>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/transform.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      SPEED UP TRANSFORMATION
                    </p>
                    <p className="pt-2 text-white">
                      Rapidly transform how work gets done with streamlined
                      application development.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/lowcost.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      REDUCE EXPENSES
                    </p>
                    <p className="pt-2 text-white">
                      Get more out of your low-code platform without hiring more
                      developers.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/productive.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      UNLEASH PRODUCTIVITY
                    </p>
                    <p className="pt-2 text-white">
                      Empower your team to create unique solutions that
                      positively impact your business.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="what-to-expect" style={{ padding: "5rem 0" }}>
              <div className="container pb-5">
                <div className="row">
                  <div className="col-lg-12 col-md-8 mx-auto text-center">
                    <h2 className="font-weight-medium">
                      CITIZEN DEVELOPER GUIDANCE DESIGNED BY CITIZEN DEVELOPERS
                    </h2>
                    <p className="mt-3">
                      As low-code consultants with expansive experience, we know
                      what support your developers need.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ padding: "65px 0px 0px 0px" }}>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      GET WHAT <br />
                      WORKS
                    </p>
                    <p className="my-3 pb-3">
                      We share the exact solutions and strategies we use to
                      educate our team and clients.
                    </p>
                  </div>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      UNMATCHED RESPONSIVENESS
                    </p>
                    <p className="my-3 pb-3">
                      Questions? Concerns? Idea? We’re just a quick email or a
                      call away.
                    </p>
                  </div>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      CONTINUOUS
                      <br />
                      IMPROVEMENT
                    </p>
                    <p className="my-3 pb-3">
                      We continuously seek out new resources, tools, and
                      strategies, so your team can elevate operational
                      efficiency.
                    </p>
                  </div>
                </div>
                <div className="col-lg-8 mx-auto text-center pt-5">
                  <Modal />
                </div>
              </div>
            </section>
            {/* </LazyLoad> */}
            {/* <LazyLoad height={200} once> */}
            <section
              id="it-consulting"
              className="it-consulting"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="container-fluid px-5">
                <div className="row pt-5">
                  <div className="col-lg-12 col-md-8 mx-auto text-center">
                    <h2 className="font-weight-medium">What Our Clients Say</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center mt-5 pb-5 px-5 mx-3 review-content">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <div className="">
                      <h3 className="text-green">
                        Awesome Collaborative Partners
                      </h3>
                      <p className="my-4" style={{ minHeight: "5rem" }}>
                        We have been working with Quandary for a year or two
                        now. They are great to work with and are very
                        collaborative in working with you to develop tools that
                        will work best for your organization. The development
                        process from the customer perspective is always simple
                        and easy. The team at Quandary is always very responsive
                        to our needs, and goes above and beyond to ensure that
                        we are satisfied customers.
                      </p>
                    </div>
                    <div className="py-3" style={{ marginTop: "auto" }}>
                      <div>
                        {/* <img className="avatar" src={thomas} alt="thomas" /> */}
                        <StaticImage
                          placeholder="blurred"
                          className="avatar"
                          src="../images/thomas.jpeg"
                          alt="thomas"
                          layout="constrained"
                          style={{ width: "80px" }}
                          loading="lazy"
                        />
                      </div>
                      <p>
                        Thomas Arlow
                        <br />
                        Director
                        <br />
                        Internal Audit
                      </p>
                    </div>
                  </div>

                  <div className="col text-center mt-5 pb-5 px-5 mx-3 review-content">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <div className="">
                      <h3 className="text-green">
                        Project Management Application
                      </h3>
                      <p className="my-4" style={{ minHeight: "5rem" }}>
                        The Quandary Team came to the table with an immense
                        amount of knowledge regarding a project management
                        application of QB. Their comprehensive Launch and
                        Support have been invaluable to our team.
                      </p>
                    </div>
                    <div className="py-3" style={{ marginTop: "auto" }}>
                      <div>
                        {/* <img className="avatar" src={bethany} alt="bethany" /> */}
                        <StaticImage
                          placeholder="blurred"
                          className="avatar"
                          src="../images/bethany.jpg"
                          alt="bethany"
                          layout="constrained"
                          style={{ width: "80px" }}
                          loading="lazy"
                        />
                      </div>
                      <p>
                        Bethany Hart
                        <br />
                        Digital Transformation Specialist
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col text-center mt-5 pb-5 px-5 mx-3 review-content">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <div className="">
                      <h3 className="text-green">Amazing Asset to Me</h3>
                      <p className="my-4" style={{ minHeight: "5rem" }}>
                        The team from Quandary has been unbelievable. They are
                        passionate about their work, attentive and available,
                        and always show me more possibilities because they
                        actually care about my projects and are such experts at
                        their fields. Ben Smith is stellar in so many
                        programming realms, Jeff Richey is so adept at
                        QuickBase. They are always teaching me about best
                        practices so that I am always feeling like a co-pilot
                        and not just sitting on the side-lines.
                      </p>
                    </div>
                    <div className="py-3" style={{ marginTop: "auto" }}>
                      <div>
                        {/* <img className="avatar" src={david} alt="david" /> */}
                        <StaticImage
                          placeholder="blurred"
                          className="avatar"
                          src="../images/david.jpeg"
                          alt="david"
                          layout="constrained"
                          style={{ width: "80px" }}
                          loading="lazy"
                        />
                      </div>
                      <p>
                        Dr. David Woo
                        <br />
                        M.D.
                        <br />
                        Madison Avenue TMS & Psychiatry
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="case-studies-container">
              <div id="case-studies" className="bg-indigo">
                <div className="container-fluid">
                  <div className="intro text-center">
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <p
                          className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                          style={{ fontFamily: "Futura,sans-serif" }}
                        >
                          Case Studies
                        </p>
                        <h2 className=" my-2 text-white">
                          We Save Clients 1000s of Hours. Every Year.
                        </h2>
                        {/* <p className="text-white">
                          We helped a national healthcare provider save over
                          9,000 hours in weekly labor costs by developing a
                          single application...
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Suspense fallback={<div>Loading...</div>}> */}
                <div className="">
                  <Cases
                    allSanityCaseStudy={this.props.data.allSanityCaseStudy}
                  />
                </div>
                {/* </Suspense> */}
                <div className="text-center">
                  <Link
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
                    to="/case-studies/"
                  >
                    More Case Studies
                  </Link>
                </div>
              </div>
            </section>

            <section>
              <div style={{ padding: "5rem 0" }}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col text-center">
                      <h2 className="my-2 font-weight-medium text-uppercase">
                        Give Your Citizen Developers The{" "}
                      </h2>
                      <h2 className="my-2 font-weight-medium text-uppercase text-green">
                        Tools They Need to Succeed
                      </h2>

                      <p>
                        The benefits of low code are clear. But without the
                        proper structures and guidance, application development
                        can quickly become the “Wild West.”
                        <br /> Don’t get lost in endless application sprawl that
                        wastes resources and puts your business at unnecessary
                        risk.
                      </p>
                      <div className="col-lg-5 mx-auto text-center pt-3 ">
                        <Modal />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="case-studies-container">
              <div id="case-studies" className="bg-indigo">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col text-center">
                      <h2 className="my-2 font-weight-medium text-uppercase text-white">
                        Citizen Developer FAQs
                      </h2>

                      <div className="col-lg-8 mx-auto text-center pt-3 ">
                        {accordionData &&
                          accordionData.map((data, i) => (
                            <CustomAccordion
                              key={i}
                              title={data.title}
                              content={data.content}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default citizenDevelopmentPage

export const citizenDevelopmentPageQuery = graphql`
  query citizenDevelopmentPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
