import React, { useState } from "react"
import PropTypes from "prop-types"
import "./accordion.scss"

const CustomAccordion = ({ title, content }) => {
  const [accordionStatus, setAccordionStatus] = useState(false)

  const onClicked = () => {
    setAccordionStatus(!accordionStatus)
  }

  return (
    <div
      className={`accordion ${accordionStatus ? "uncollapsed" : "collapsed"}`}
    >
      <button onClick={onClicked}>
        <b className="pr-2">{title}</b>
        <span class="arrow pl-2 ml-2 arrow-none"></span>
      </button>
      <div className="accordion-panel text-left">{content}</div>
    </div>
  )
}

export default CustomAccordion

CustomAccordion.propTypes = {
  title: PropTypes.object,
  content: PropTypes.object,
}
